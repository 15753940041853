import React, {
  useEffect,
  useState,
  useRef,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { Form } from 'react-final-form';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';

import authSelectors from 'src/store/selectors/auth';
import {
  deleteComment,
  fetchComments,
  updateComment,
} from 'src/store/actions/comments';
import commentsSelectors from 'src/store/selectors/comments';

import AlertDialog from 'src/components/AlertDialog';
import Page from 'src/components/Page';
import EditCommentDialog from 'src/components/EditCommentDialog';

import Results from '../components/Results';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CommentsListView = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const deleteAlertRef = useRef();

  const [isEditCommentOpen, setIsEditCommentOpen] = useState(false);
  const [selectedComment, setSelectedCommentData] = useState();
  const [selectedId, setSelectedId] = useState(null);

  const search = searchParams.get('search') || null;
  const currentPage = parseInt(searchParams.get('page') || 1, 10);
  const rowsPerPage = parseInt(searchParams.get('limit') || 10, 10);

  const userData = useSelector((state) => authSelectors.getUser(state));
  const isFetching = useSelector((state) => commentsSelectors.getCommentsIsFetching(state));

  const fetchData = async () => {
    try {
      await dispatch(fetchComments({
        search,
        page: currentPage,
        limit: rowsPerPage,
      }));
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Wystąpił problem podczas pobierania listy użytkowników', { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handleChangePage = (event, value) => {
    setSearchParams({
      page: value + 1,
      limit: rowsPerPage,
    });
  };

  const handleLimitChange = (event) => {
    const { value } = event.target;
    setSearchParams({
      page: currentPage,
      limit: value,
    });
  };

  const openEditCommentModal = (data = { user: userData }) => {
    setSelectedCommentData(data);
    setIsEditCommentOpen(true);
  };

  const handleEdit = async ({
    content,
    date,
    galleryItems,
    rate,
    user,
  }) => {
    try {
      const commentData = new FormData();

      const getUsername = (value) => {
        if (!value.id) {
          return value.username || value;
        }

        return null;
      };

      commentData.append('content', content);
      commentData.append('rate', rate);
      commentData.append('created', new Date(date));
      commentData.append('userId', user.id || null);
      commentData.append('username', getUsername(user));

      if (galleryItems.length > 0) {
        const filesToRemove = [];

        galleryItems.forEach((item) => {
          if (!item.deleted) {
            if (item.file.path) {
              commentData.append('gallery', item.file);
            }
          } else {
            filesToRemove.push(item.id);
          }
        });
        commentData.append('galleryOrder', galleryItems.map(({ deleted, file }) => !deleted && file.name));
        if (filesToRemove.length > 0) {
          commentData.append('filesToRemove', filesToRemove.join(','));
        }
      }

      await dispatch(
        updateComment(selectedComment.id, commentData)
      );

      setIsEditCommentOpen(false);
      setSelectedCommentData();
      enqueueSnackbar('Komentarz zapisany', { variant: 'success' });

      fetchData();
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Wystąpił problem podczas zapisywania komentarza', { variant: 'error' });
    }
  };

  const openDeleteCommentModal = (comment) => {
    deleteAlertRef.current.open();
    setSelectedId(comment.id);
  };

  const handleDelete = async () => {
    try {
      await dispatch(deleteComment(selectedId));
      deleteAlertRef.current.close();
      setSelectedId(null);
      enqueueSnackbar('Komentarz usunięty', { variant: 'success' });

      fetchData();
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Wystąpił problem podczas usuwania komentarza', { variant: 'error' });
    }
  };

  return (
    <Page
      className={classes.root}
      title="Użytkownicy"
    >
      <Container maxWidth={false}>
        <Box mt={3}>
          <Results
            handleChangePage={handleChangePage}
            handleLimitChange={handleLimitChange}
            openEditCommentModal={openEditCommentModal}
            openDeleteCommentModal={openDeleteCommentModal}
          />
        </Box>
      </Container>
      <Form
        onSubmit={() => {}}
        render={() => (
          <EditCommentDialog
            callback={handleEdit}
            comment={selectedComment}
            isOpen={isEditCommentOpen}
            setIsOpen={setIsEditCommentOpen}
          />
        )}
      />
      <AlertDialog
        title="Usuwanie komentarza"
        message="Czy na pewno chcesz usunąć ten komentarz?"
        ref={deleteAlertRef}
        actions={[
          {
            color: 'secondary',
            label: 'Anuluj',
          },
          {
            autoFocus: true,
            color: 'secondary',
            disabled: isFetching,
            label: 'Usuń',
            loading: isFetching,
            variant: 'contained',
            onClick: handleDelete,
          },
        ]}
      />
    </Page>
  );
};

export default CommentsListView;
