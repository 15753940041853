import request from '../../utils/request';
import { recalculateCounters } from './places';

const PREFIX = '[SETTINGS]';

export const GET_SETTINGS_REQUEST = `${PREFIX} GET_SETTINGS_REQUEST`;
export const GET_SETTINGS_SUCCESS = `${PREFIX} GET_SETTINGS_SUCCESS`;
export const GET_SETTINGS_FAILURE = `${PREFIX} GET_SETTINGS_FAILURE`;

const fetchSettingsRequest = () => ({
  type: GET_SETTINGS_REQUEST,
});

const fetchSettingsSuccess = (payload) => ({
  payload,
  type: GET_SETTINGS_SUCCESS,
});

const fetchSettingsFailure = () => ({
  type: GET_SETTINGS_FAILURE,
});

export const fetchSettings = () => (dispatch) => {
  dispatch(fetchSettingsRequest());

  return request
    .get('v1/settings/')
    .then(({ data }) => {
      dispatch(fetchSettingsSuccess(data));

      return data;
    })
    .catch((error) => {
      dispatch(fetchSettingsFailure());

      throw error;
    });
};

export const uploadImage = (payload) => () => {
  return request
    .post('v1/settings/picture', payload)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const RUN_RECALCULATING = `${PREFIX} RUN_RECALCULATING`;

const runRecalculatingRequest = () => ({
  type: RUN_RECALCULATING,
});

export const runRecalculating = () => (dispatch) => {
  dispatch(runRecalculatingRequest());

  recalculateCounters();
};

export const SAVE_TRANSLATED_SIGNS_LIMIT = `${PREFIX} SAVE_TRANSLATED_SIGNS_LIMIT`;

const saveTranslatedSignsLimitSuccess = (payload) => ({
  type: SAVE_TRANSLATED_SIGNS_LIMIT,
  payload,
});

export const saveTranslatedSignsLimit = (value) => (dispatch) => {
  return request
    .post('v1/settings/save-limit', { value })
    .then(() => {
      dispatch(saveTranslatedSignsLimitSuccess(value));
      return true;
    })
    .catch((error) => {
      throw error;
    });
};
export const SAVE_TRANSLATION_API_KEY_INDEX = `${PREFIX} SAVE_TRANSLATION_API_KEY_INDEX`;

const saveTranslationApiKeyIndexSuccess = (payload) => ({
  type: SAVE_TRANSLATION_API_KEY_INDEX,
  payload,
});

export const saveTranslationApiKeyIndex = (value) => (dispatch) => {
  return request
    .post('v1/settings/save-translation-api-key-index', { value })
    .then(() => {
      dispatch(saveTranslationApiKeyIndexSuccess(value));
      return true;
    })
    .catch((error) => {
      throw error;
    });
};

export const SAVE_AUTOCOMPLETE_API_KEY_INDEX = `${PREFIX} SAVE_AUTOCOMPLETE_API_KEY_INDEX`;

const saveAutocompleteApiKeyIndexSuccess = (payload) => ({
  type: SAVE_AUTOCOMPLETE_API_KEY_INDEX,
  payload,
});

export const saveAutocompleteApiKeyIndex = (value) => (dispatch) => {
  return request
    .post('v1/settings/save-autocomplete-api-key-index', { value })
    .then(() => {
      dispatch(saveAutocompleteApiKeyIndexSuccess(value));
      return true;
    })
    .catch((error) => {
      throw error;
    });
};
