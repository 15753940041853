import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  useParams,
} from 'react-router-dom';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSnackbar } from 'notistack';

import authSelectors from 'src/store/selectors/auth';
import {
  addComment,
  deleteComment,
  updateComment,
} from 'src/store/actions/comments';

import {
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Box
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import AlertDialog from 'src/components/AlertDialog';
import EditCommentDialog from 'src/components/EditCommentDialog';
import TableLoading from 'src/components/TableLoading';
import CommentItem from './CommentItem';

const useStyles = makeStyles((theme) => ({
  noResults: {
    width: '100%',
    height: '100%',
    paddingBottom: 50,
    borderBottom: 'none',
    color: theme.palette.text.disabled,
  },
  noResultsIcon: {
    display: 'block',
    margin: '50px auto 0',
    fontSize: 64,
  },
}));

const Comments = ({
  fetchData,
  selectors,
  showPlace,
  showUser,
  showActions,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const deleteAlertRef = useRef();
  const classes = useStyles();

  const [isEditCommentOpen, setIsEditCommentOpen] = useState(false);
  const [selectedComment, setSelectedCommentData] = useState();
  const [currentPage, setPage] = useState(0);

  const userData = useSelector((state) => authSelectors.getUser(state));
  const comments = useSelector((state) => selectors.getComments(state));
  const isFetching = useSelector((state) => selectors.getCommentsIsFetching(state));
  const pagination = useSelector((state) => selectors.getCommentsPaginationData(state));

  const fetchComments = useCallback(async ({
    page = currentPage + 1,
    limit = 5,
  } = {}) => {
    await dispatch(fetchData(id, { page, limit }))
      .catch(() => {
        enqueueSnackbar('Wystąpił problem podczas pobierania komentarzy', { variant: 'error' });
      });
  }, [currentPage, dispatch, enqueueSnackbar, fetchData, id]);

  useEffect(() => {
    fetchComments();
  }, [fetchComments, id]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const openEditCommentModal = (data = { user: userData }) => {
    setSelectedCommentData(data);
    setIsEditCommentOpen(true);
  };

  const handleSubmit = async ({
    content,
    date,
    galleryItems,
    rate,
    user,
  }) => {
    try {
      const commentData = new FormData();

      const getUsername = (value) => {
        if (!value.id) {
          return value.username || value;
        }

        return null;
      };

      commentData.append('content', content);
      commentData.append('rate', rate);
      commentData.append('created', new Date(date));
      commentData.append('placeId', id);
      commentData.append('userId', user.id || null);
      commentData.append('username', getUsername(user));

      if (galleryItems.length > 0) {
        const filesToRemove = [];

        galleryItems.forEach((item) => {
          if (!item.deleted) {
            if (item.file.path) {
              commentData.append('gallery', item.file);
            }
          } else {
            filesToRemove.push(item.id);
          }
        });
        commentData.append('galleryOrder', galleryItems.map(({ deleted, file }) => !deleted && file.name));
        if (filesToRemove.length > 0) {
          commentData.append('filesToRemove', filesToRemove.join(','));
        }
      }

      if (selectedComment.id) {
        await dispatch(
          updateComment(selectedComment.id, commentData)
        );
      } else {
        await dispatch(
          addComment(commentData)
        );
      }
      setIsEditCommentOpen(false);
      setSelectedCommentData();
      enqueueSnackbar('Komentarz zapisany', { variant: 'success' });

      await fetchComments();
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Wystąpił problem podczas zapisywania komentarza', { variant: 'error' });
    }
  };

  const openDeleteCommentModal = (data) => {
    deleteAlertRef.current.open();
    setSelectedCommentData(data);
  };

  const handleDelete = async () => {
    try {
      await dispatch(deleteComment(selectedComment.id));
      deleteAlertRef.current.close();
      setSelectedCommentData();
      enqueueSnackbar('Komentarz usunięty', { variant: 'success' });

      await fetchComments();
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Wystąpił problem podczas usuwania komentarza', { variant: 'error' });
    }
  };

  return (
    <Card>
      <CardHeader title="Komentarze" />
      <Divider />
      <PerfectScrollbar>
        <Box
          minWidth={1050}
          minHeight={160}
        >
          {isFetching && <TableLoading />}
          <Table>
            <TableHead>
              <TableRow>
                {showPlace && (
                  <TableCell width={250}>
                    Miejsce
                  </TableCell>
                )}
                {showUser && (
                  <TableCell width={250}>
                    Użytkownik
                  </TableCell>
                )}
                <TableCell width={152}>
                  Ocena
                </TableCell>
                <TableCell>
                  Komentarz
                </TableCell>
                <TableCell width={200}>
                  Data dodania
                </TableCell>
                {showActions && <TableCell width={130} />}
              </TableRow>
            </TableHead>
            {comments && (
              <TableBody>
                {comments.length > 0 ? comments.map((comment) => (
                  <CommentItem
                    key={comment.id}
                    comment={comment}
                    openDeleteCommentModal={openDeleteCommentModal}
                    openEditCommentModal={openEditCommentModal}
                    showActions={showActions}
                    showPlace={showPlace}
                    showUser={showUser}
                  />
                )) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={5}
                      className={classes.noResults}
                    >
                      <CloseIcon className={classes.noResultsIcon} />
                      Brak komentarzy
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={pagination.totalItems}
        onPageChange={handlePageChange}
        page={currentPage}
        rowsPerPage={parseInt(pagination.itemsPerPage, 10)}
        rowsPerPageOptions={[]}
      />
      <Divider />
      <Box padding={1}>
        <Button
          color="secondary"
          fullWidth
          variant="text"
          onClick={() => openEditCommentModal()}
        >
          Dodaj komentarz
        </Button>
      </Box>
      <EditCommentDialog
        callback={handleSubmit}
        comment={selectedComment}
        isOpen={isEditCommentOpen}
        setIsOpen={setIsEditCommentOpen}
      />
      <AlertDialog
        title="Usuwanie komentarza"
        message="Czy na pewno chcesz usunąć ten komentarz?"
        ref={deleteAlertRef}
        actions={[
          {
            color: 'secondary',
            label: 'Anuluj',
          },
          {
            autoFocus: true,
            color: 'secondary',
            disabled: isFetching,
            label: 'Usuń',
            loading: isFetching,
            variant: 'contained',
            onClick: handleDelete,
          },
        ]}
      />
    </Card>
  );
};

Comments.propTypes = {
  fetchData: PropTypes.func.isRequired,
  selectors: PropTypes.shape({
    getComments: PropTypes.func.isRequired,
    getCommentsPaginationData: PropTypes.func.isRequired,
    getCommentsIsFetching: PropTypes.func.isRequired,
  }).isRequired,
  showActions: PropTypes.bool,
  showPlace: PropTypes.bool,
  showUser: PropTypes.bool,
};

Comments.defaultProps = {
  showActions: true,
  showPlace: true,
  showUser: true,
};

export default Comments;
